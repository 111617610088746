import React, { createContext, useReducer } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { appReducer, initialState } from "../reducer";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";
import { HomePage } from "../pages/HomePage";
import { CartPage } from "../pages/CartPage";
import { AboutPage } from "../pages/AboutPage";
import { AdminPage } from "../pages/admin/AdminPage";
import { OrderDetailPage } from "../pages/OrderDetailPage";

export const AppContext = createContext(undefined);

export const App = () => {
  const [state, dispatch] = useReducer(appReducer, initialState());

  return (
    <div className="container">
      <AppContext.Provider value={{ state, dispatch }}>
        <BrowserRouter>
          <NavBar/>
          <Switch>
            <Route path="/cart" component={CartPage}/>
            <Route path="/about" component={AboutPage}/>
            <Route path="/admin" component={AdminPage}/>
            <Route path="/orders/:id" component={OrderDetailPage}/>
            <Route path="/" component={HomePage}/>
          </Switch>
          <Footer/>
        </BrowserRouter>
      </AppContext.Provider>
    </div>
  );
};
