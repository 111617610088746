import React, { useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom';

type TParams = { token: string };

export const AdminLoginPage = (props: RouteComponentProps<TParams>) => {
  const history = useHistory();

  useEffect(() => {
    window.localStorage.setItem("authToken", props.match.params.token);
    history.push("/admin");
  }, []);

  return (
    <div></div>
  );
}
