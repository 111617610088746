import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ApiService from '../../../services/ApiService';
import lang, { getLang } from '../../../services/lang';

export const ProductListPage = () => {
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  const loadProducts = () => {
    setLoading(true);

    ApiService.getProducts(prods => {
      setProducts(prods);
      setLoading(false);
    });
  };

  useEffect(loadProducts, []);

  const deleteProduct = (id: string) => {
    if (!confirm(lang.admin_list_products_confirm_delete)) {
      return;
    }
    ApiService.deleteProduct(id, loadProducts);
  };

  const renderTable = () =>
    <table className="table">
      <tr>
        <th>{lang.admin_list_products_header_image}</th>
        <th>{lang.admin_list_products_header_name}</th>
        <th>{lang.admin_list_products_header_description}</th>
        <th>{lang.admin_list_products_header_price}</th>
        <th>{lang.admin_list_products_header_action}</th>
      </tr>
      {
        products.map(p => <tr>
          <td style={{ width: '100px' }}><img src={p.imageUrl} alt={p.names[getLang()]} className="img-thumbnail img-thumbnail-sm"/>
          </td>
          <td>{p.names[getLang()]}</td>
          <td>{p.descriptions[getLang()]}</td>
          <td style={{ width: '50px' }}>${p.price}</td>
          <td style={{ width: '150px' }}>
            <Link to={`/admin/products/edit/${p.id}`}
                  className="btn btn-sm btn-outline-success mr-2">{lang.admin_list_products_button_edit}</Link>
            <button type="button" className="btn btn-sm btn-outline-danger"
                    onClick={() => deleteProduct(p.id)}>{lang.admin_list_products_button_delete}</button>
          </td>
        </tr>)
      }
    </table>;

  return (
    <>
      <h2>
        {lang.admin_list_products_title}
        <Link to="/admin/products/new" className="btn btn-outline-primary float-right">
          <i className="fa fa-plus mr-2" aria-hidden="true"></i>
          {lang.admin_list_products_link_new_peoduct}
        </Link>
      </h2>
      <hr/>
      {loading ? <h3>{lang.loading}</h3> : products.length === 0 ?
        <h3>{lang.admin_list_products_no_products}</h3> : renderTable()}
    </>
  )
}
