import React, { useEffect, useState } from 'react'
import { ProductBox } from '../components/ProductBox'
import { Welcome } from '../components/Welcome';
import api from '../services/ApiService';
import lang from '../services/lang';
import { ProductModel } from '../models';

export const HomePage = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setLoading(true);
    api.getProducts((products: ProductModel[]) => {
      setProducts(products);
      setLoading(false);
    })
  }, []);

  return (
    <div className="py-3">
      <Welcome/>
      {loading ? <h2>{lang.loading}</h2> : products.map(p => <ProductBox key={p.id} product={p}/>)}
    </div>
  );
};