import React from 'react'
import { getLang } from '../services/lang'

const Nl = () => {
  return (
    <div>X</div>
  );
}

const Tr = () => {
  return (
    <div>Hakkimizda</div>
  );
}

const En = () => {
  return (
    <div>About Us</div>
  );
}

export const AboutPage = () => {
  return (
    <div className="my-3">
      {getLang() == "en" ? <En/> : getLang() == "tr" ? <Tr/> : <Nl/>}
    </div>
  )
}