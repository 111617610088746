import React from 'react'
import { Link } from 'react-router-dom'
import lang from '../services/lang'

export const Footer = () => {
  return (
    <div className="pb-5 text-center">
      <hr/>
      <Link to="/" className="mr-3">{lang.footer_link_home}</Link>
      <Link to="/about">{lang.footer_link_about}</Link>
      <hr/>
      &copy; {new Date().getFullYear()} {lang.footer_company_name}
    </div>
  );
};
