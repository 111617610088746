import en from './en'
import tr from './tr'
import nl from './nl'

const dictionaries = { en, tr, nl };

export const langs = {
  nl: "🇳🇱 Nederlands",
  tr: "🇹🇷 Türkçe",
  en: "🇬🇧 English"
};

export function getLang() {
  return window.localStorage.getItem("lang") || "en";
}

export function setLang(lang) {
  window.localStorage.setItem("lang", lang);
}

export default dictionaries[getLang()];
