import React from 'react';
import { getLang, langs, setLang } from '../services/lang';

export const LanguageSelector = () => {
  const changeLang = (e, lang) => {
    e.preventDefault();
    setLang(lang);
    window.location.reload();
  };

  return (
    <div className="dropdown mr-1 lang-selector">
      <button className="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {langs[getLang()]}
      </button>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {Object.keys(langs).map(lang => <a className="dropdown-item" href="#"
                                           onClick={e => changeLang(e, lang)}>{langs[lang]}</a>)}
      </div>
    </div>
  );
};
