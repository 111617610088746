import axios, { AxiosResponse } from "axios";
import { OrderModel, ProductModel } from "../models";

const API_BASE = window.location.hostname === "ozyoghurt.nl"
  ? "https://api.ozyoghurt.nl/api"       // prod
  : "https://api.dev.ozyoghurt.nl/api";  // local & dev

type GetProductsResponse = { products: ProductModel[] };
type GetProductResponse = { product: ProductModel };
type CreateProductResponse = { id: string }
type GetOrdersResponse = { orders: OrderModel[] };
type CreateOrderResponse = { id: string }
type GetOrderResponse = { order: OrderModel };

function getAuthToken() {
  return window.localStorage.getItem("authToken");
}

const axiosConfig = () => {
  return {
    headers: {
      "X-AuthToken": getAuthToken() || '',
    }
  };
};

class ApiService {
  cathErr = err => {
    if (err.response && err.response.data && err.response.data.message && err.response.status >= 400 && err.response.status < 500) {
      alert("ERROR: " + err.response.data.message);
    } else {
      console.log(JSON.stringify(err));
    }
  };

  getProducts(callback: (products: ProductModel[]) => void) {
    axios.get(API_BASE + "/products", axiosConfig())
      .then((resp: AxiosResponse<GetProductsResponse>) => callback(resp.data.products))
      .catch(this.cathErr);
  }

  getProduct(id: string, callback: (product: ProductModel) => void) {
    axios.get(API_BASE + "/products/" + encodeURIComponent(id), axiosConfig())
      .then((resp: AxiosResponse<GetProductResponse>) => callback(resp.data.product))
      .catch(this.cathErr);
  }

  createProduct(product: ProductModel, callback: (id: string) => void) {
    axios.post(API_BASE + "/products", { product }, axiosConfig())
      .then((resp: AxiosResponse<CreateProductResponse>) => callback(resp.data.id))
      .catch(this.cathErr);
  }

  updateProduct(product: ProductModel, callback: () => void) {
    axios.put(API_BASE + "/products/" + product.id, { product }, axiosConfig())
      .then(() => callback())
      .catch(this.cathErr);
  }

  deleteProduct(id: string, callback: () => void) {
    axios.delete(API_BASE + "/products/" + id, axiosConfig())
      .then(() => callback())
      .catch(this.cathErr);
  }

  getOrders(status: number, callback: (orders: OrderModel[]) => void) {
    axios.get(API_BASE + "/orders?status=" + status, axiosConfig())
      .then((resp: AxiosResponse<GetOrdersResponse>) => callback(resp.data.orders))
      .catch(this.cathErr);
  }

  createOrder(order: OrderModel, callback: (id: string) => void) {
    axios.post(API_BASE + "/orders", { order }, axiosConfig())
      .then((resp: AxiosResponse<CreateOrderResponse>) => callback(resp.data.id))
      .catch(this.cathErr);
  }

  updateOrder(order: OrderModel, callback: () => void) {
    axios.put(API_BASE + "/orders/" + order.id, { order }, axiosConfig())
      .then(() => callback())
      .catch(this.cathErr);
  }

  getOrder(orderId: string, callback: (order: OrderModel) => void) {
    axios.get(API_BASE + "/orders/" + orderId, axiosConfig())
      .then((resp: AxiosResponse<GetOrderResponse>) => callback(resp.data.order))
      .catch(this.cathErr);
  }
}

export default new ApiService();
