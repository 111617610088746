import React, { useContext } from 'react'
import { AppContext } from '../components/App';
import { CartItem } from '../components/CartItem';
import { Checkout } from '../components/Checkout';
import { ACTION_ORDER_CREATED } from '../reducer';
import { useHistory } from 'react-router-dom';
import lang from '../services/lang';

export const CartPage = () => {
  const { state, dispatch } = useContext(AppContext);
  const history = useHistory();

  const onOrderCreated = (orderId: string) => {
    dispatch({ type: ACTION_ORDER_CREATED });
    history.push("/orders/" + orderId);
  };

  if (state.cart.items.length === 0) {
    return <h3 className="text-center my-3">{lang.cart_empty_cart}</h3>;
  }

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <h2 className="mt-3">{lang.cart_title}</h2>
        <hr/>
        {state.cart.items.map(cartItem => <CartItem key={cartItem.product.id} cartItem={cartItem}/>)}
      </div>
      <div className="col-12 col-md-6">
        <h2 className="mt-3">{lang.cart_checkout_title}</h2>
        <hr/>
        <Checkout cart={state.cart} onOrderCreated={onOrderCreated}/>
      </div>
    </div>
  );
};