import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { OrderListPage } from './orders/OrderListPage'
import { CreateProductPage } from './products/CreateProductPage'
import { UpdateProductPage } from './products/UpdateProductPage'
import { ProductListPage } from './products/ProductListPage'
import { AdminNav } from './AdminNav'
import { AdminLoginPage } from './AdminLoginPage'
import lang from '../../services/lang'

export const AdminPage = () => {
  if (!getAuthToken()) {
    return <div className="my-3">
      <h1>{lang.admin_unautohrized}</h1>
      <Switch>
        <Route path="/admin/login/:token" component={AdminLoginPage}/>
      </Switch>
    </div>
  }

  return (
    <div className="my-3">
      <AdminNav/>
      <hr/>
      <Switch>
        <Route path="/admin/login/:token" component={AdminLoginPage}/>
        <Route path="/admin/orders" component={OrderListPage}/>
        <Route exact path="/admin/products" component={ProductListPage}/>
        <Route path="/admin/products/new" component={CreateProductPage}/>
        <Route path="/admin/products/edit/:id" component={UpdateProductPage}/>
        <Redirect to="/admin/orders"/>
      </Switch>
    </div>
  )
}

function getAuthToken() {
  return window.localStorage.getItem("authToken");
}