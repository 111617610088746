export default {
  // general
  currency_symbol: "X",
  loading: "X",
  order_status_active: "X",
  order_status_cancelled: "X",
  order_status_delivered: "X",
  order_status_all: "X",
  // navbar
  navbar_app_name: "X",
  navbar_link_about: "X",
  navbar_button_cart: "X",
  navbar_button_signout: "X",
  // welcome
  welcome_title: "X",
  welcome_text1: "X",
  welcome_text2: "X",
  // product
  product_quantity: "X",
  product_add_to_cart: "X",
  // cart
  cart_empty_cart: "X",
  cart_title: "X",
  cart_update_quantity: "X",
  cart_remove_item: "X",
  cart_remove_item_confirm: "X",
  cart_checkout_title: "X",
  // checkout
  checkout_fullname_label: "X",
  checkout_fullname_placeholder: "X",
  checkout_fullname_validation_error: "X",
  checkout_phone_label: "X",
  checkout_phone_placeholder: "X",
  checkout_phone_validation_error: "X",
  checkout_email_label: "X",
  checkout_email_placeholder: "X",
  checkout_email_validation_error: "X",
  checkout_address_label: "X",
  checkout_address_placeholder: "X",
  checkout_address_validation_error: "X",
  checkout_notes_label: "X",
  checkout_notes_placeholder: "X",
  checkout_total_price_label: "X",
  checkout_place_order_button: "X",
  // footer
  footer_link_home: "X",
  footer_link_about: "X",
  footer_company_name: "X",
  // order detail
  order_detail_info: "X",
  // admin page
  admin_unautohrized: "X",
  // admin nav
  admin_nav_link_orders: "X",
  admin_nav_link_products: "X",
  // admin update product
  admin_update_product_title: "X",
  // admin create product
  admin_create_product_title: "X",
  // admin product editor
  admin_product_editor_language_label: "X",
  admin_product_editor_name_label: "X",
  admin_product_editor_name_placeholder: "X",
  admin_product_editor_description_label: "X",
  admin_product_editor_description_placeholder: "X",
  admin_product_editor_price_label: "X",
  admin_product_editor_price_placeholder: "X",
  admin_product_editor_image_label: "X",
  admin_product_editor_image_placeholder: "X",
  admin_product_editor_cancel_button: "X",
  // admin list products
  admin_list_products_title: "X",
  admin_list_products_link_new_peoduct: "X",
  admin_list_products_no_products: "X",
  admin_list_products_header_image: "X",
  admin_list_products_header_name: "X",
  admin_list_products_header_description: "X",
  admin_list_products_header_price: "X",
  admin_list_products_header_action: "X",
  admin_list_products_button_edit: "X",
  admin_list_products_button_delete: "X",
  admin_list_products_confirm_delete: "X",
  // admin order list
  admin_order_list_title: "X",
  admin_order_list_no_orders: "X",
  // order viewer
  order_viewer_status_label: "X",
  order_viewer_phone_label: "X",
  order_viewer_address_label: "X",
  order_viewer_email_label: "X",
  order_viewer_notes_label: "X",
  order_viewer_total_label: "X",
  order_viewer_cancel_button: "X",
  order_viewer_delivered_button: "X",
  order_viewer_confirm_status_update: "X",
}