export default {
  // general
  currency_symbol: "€",
  loading: "Loading...",
  order_status_active: "Active",
  order_status_cancelled: "Cancelled",
  order_status_delivered: "Delivered",
  order_status_all: "All",
  // navbar
  navbar_app_name: "Yogurtcu",
  navbar_link_about: "About",
  navbar_button_cart: "Cart",
  navbar_button_signout: "Sign Out",
  // welcome
  welcome_title: "Welcome to Yogurtcu!",
  welcome_text1: "This is a website where you can buy yogurt!",
  welcome_text2: "Some more info about the web site...",
  // product
  product_quantity: "Quantity",
  product_add_to_cart: "Add to Cart",
  // cart
  cart_empty_cart: "Your Cart is empty!",
  cart_title: "Your Cart",
  cart_update_quantity: "Update Quantity",
  cart_remove_item: "Remove Item",
  cart_remove_item_confirm: "Item will be removed from cart?",
  cart_checkout_title: "Checkout",
  // checkout
  checkout_fullname_label: "Fullname",
  checkout_fullname_placeholder: "Your first and last name",
  checkout_fullname_validation_error: "Please enter your first and last name!",
  checkout_phone_label: "Phone",
  checkout_phone_placeholder: "Your phone number",
  checkout_phone_validation_error: "Please enter your phone number!",
  checkout_email_label: "Email",
  checkout_email_placeholder: "Your e-mail address",
  checkout_email_validation_error: "Please enter your e-mail address!",
  checkout_address_label: "Address",
  checkout_address_placeholder: "Delivery address",
  checkout_address_validation_error: "Please enter delivery address!",
  checkout_notes_label: "Extra Notes",
  checkout_notes_placeholder: "Any additional notes",
  checkout_total_price_label: "Total Price",
  checkout_place_order_button: "Place Order",
  // footer
  footer_link_home: "Home",
  footer_link_about: "About",
  footer_company_name: "Yogurtcu B.V.",
  // order detail
  order_detail_info: "You can save this address to access your order details later.",
  // admin page
  admin_unautohrized: "Unauthorized!",
  // admin nav
  admin_nav_link_orders: "Orders",
  admin_nav_link_products: "Products",
  // admin update product
  admin_update_product_title: "Update Product",
  // admin create product
  admin_create_product_title: "Create Product",
  // admin product editor
  admin_product_editor_language_label: "Language",
  admin_product_editor_name_label: "Name",
  admin_product_editor_name_placeholder: "Product name",
  admin_product_editor_description_label: "Description",
  admin_product_editor_description_placeholder: "Product description",
  admin_product_editor_price_label: "Price",
  admin_product_editor_price_placeholder: "9.25",
  admin_product_editor_image_label: "Image Url",
  admin_product_editor_image_placeholder: "Product image url http://...",
  admin_product_editor_cancel_button: "Cancel",
  // admin list products
  admin_list_products_title: "Products",
  admin_list_products_link_new_peoduct: "New Product",
  admin_list_products_no_products: "No products found!",
  admin_list_products_header_image: "Image",
  admin_list_products_header_name: "Name",
  admin_list_products_header_description: "Description",
  admin_list_products_header_price: "Price",
  admin_list_products_header_action: "Action",
  admin_list_products_button_edit: "Edit",
  admin_list_products_button_delete: "Delete",
  admin_list_products_confirm_delete: "Are you sure?",
  // admin order list
  admin_order_list_title: "Orders",
  admin_order_list_no_orders: "No orders found!",
  // order viewer
  order_viewer_status_label: "Status",
  order_viewer_phone_label: "Phone",
  order_viewer_address_label: "Address",
  order_viewer_email_label: "Email",
  order_viewer_notes_label: "Notes",
  order_viewer_total_label: "Total",
  order_viewer_cancel_button: "Cancel",
  order_viewer_delivered_button: "Mark as Delivered",
  order_viewer_confirm_status_update: "Are you sure?",
}