import React, { useEffect, useState } from "react";
import { ProductModel } from "../models";
import { Link } from "react-router-dom";
import lang, { getLang, langs } from "../services/lang";

type ProductEditorProps = {
  buttonText: string,
  onSubmit: (product: ProductModel) => void,
  product: ProductModel
};

export const ProductEditor = (props: ProductEditorProps) => {
  const [product, setProduct] = useState({ ...props.product });
  const [language, setLanguage] = useState(getLang());

  const handleSubmit = e => {
    e.preventDefault();
    props.onSubmit(product);
  };

  useEffect(() => {
    Object.keys(langs).forEach(lang => {
      product.names[lang] = product.names[lang] || '';
      product.descriptions[lang] = product.descriptions[lang] || '';
    });
    setProduct({ ...product });
  }, []);

  const updateProductProperty = e => setProduct({ ...product, [e.target.name]: e.target.value });

  const updateProductMultiLanguageProperty = e => {
    const multiLangField = { ...product[e.target.name] };
    multiLangField[language] = e.target.value;
    setProduct({ ...product, [e.target.name]: multiLangField });
  };

  const updateProductPrice = e => {
    let price = parseFloat(e.target.value);
    if (isNaN(price)) {
      price = 0;
    }
    setProduct({ ...product, price });
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <div className="form-group">
        <div className="form-group">
          <label htmlFor="language">{lang.admin_product_editor_language_label}</label>
          <select className="form-control" name="language" id="language" defaultValue={language}
                  onChange={e => setLanguage(e.target.value)}>
            {Object.keys(langs).map(lang => <option value={lang}>{langs[lang]}</option>)}
          </select>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="names">{lang.admin_product_editor_name_label}</label>
        <input value={product.names[language]} onChange={updateProductMultiLanguageProperty} type="text" name="names"
               id="names" className="form-control" placeholder={lang.admin_product_editor_name_placeholder}
               aria-describedby="names"/>
      </div>
      <div className="form-group">
        <label htmlFor="descriptions">{lang.admin_product_editor_description_label}</label>
        <textarea value={product.descriptions[language]} onChange={updateProductMultiLanguageProperty}
                  name="descriptions" className="form-control"
                  placeholder={lang.admin_product_editor_description_placeholder} id="descriptions" rows={2}/>
      </div>
      <div className="form-group">
        <label htmlFor="price">{lang.admin_product_editor_price_label}</label>
        <input value={product.price || ''} onChange={updateProductPrice} type="number" name="price" id="price"
               className="form-control" placeholder={lang.admin_product_editor_price_placeholder}
               aria-describedby="price"/>
      </div>
      <div className="form-group">
        <label htmlFor="imageUrl">{lang.admin_product_editor_image_label}</label>
        <input value={product.imageUrl} onChange={updateProductProperty} type="text" name="imageUrl"
               className="form-control" placeholder={lang.admin_product_editor_image_placeholder} id="imageUrl"/>
      </div>
      <button type="submit" className="btn btn-primary">{props.buttonText}</button>
      <Link to="/admin/products"
            className="btn btn-warning float-right">{lang.admin_product_editor_cancel_button}</Link>
    </form>
  );
};
