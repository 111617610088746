import { AppStateModel, CartItemModel, CartModel } from "./models";

export const ACTION_ADD_TO_CART = 'ACTION_ADD_TO_CART';
export const ACTION_UPDATE_QUANTITY_IN_CART = 'ACTION_UPDATE_QUANTITY_IN_CART';
export const ACTION_ORDER_CREATED = 'ACTION_ORDER_CREATED';

type Action = { type: string, payload: any };

const addToCart = (state: AppStateModel, cartItem: CartItemModel) => {
  const updatedCart = { ...state.cart };
  let item = updatedCart.items.filter(item => item.product.id === cartItem.product.id)[0];
  if (item) {
    item.quantity += cartItem.quantity;
  } else {
    updatedCart.items.push(cartItem);
  }

  return {
    ...state, cart: updatedCart
  };
};

const updateQuantityInCart = (state: AppStateModel, cartItem: CartItemModel, newQuantity: number) => {
  let updatedItems = state.cart.items;
  if (newQuantity === 0) {
    updatedItems = updatedItems.filter(item => item.product.id !== cartItem.product.id);
  } else {
    let item = updatedItems.filter(item => item.product.id === cartItem.product.id)[0];
    item.quantity = newQuantity;
  }

  return {
    ...state, cart: { items: updatedItems }
  };
};

const onOrderCreated = (state: AppStateModel) => {
  return {
    ...state, cart: new CartModel()
  };
};

export const appReducer = (state: AppStateModel, action: Action) => {
  let newState: AppStateModel;
  switch (action.type) {
    case ACTION_ADD_TO_CART:
      newState = addToCart(state, action.payload.cartItem);
      break;
    case ACTION_UPDATE_QUANTITY_IN_CART:
      newState = updateQuantityInCart(state, action.payload.cartItem, action.payload.newQuantity);
      break;
    case ACTION_ORDER_CREATED:
      newState = onOrderCreated(state);
      break;
    default:
      newState = { ...state };
      break;
  }
  window.sessionStorage.setItem('appState', JSON.stringify(newState));
  return newState;
};

export const initialState = () => {
  const json = window.sessionStorage.getItem('appState');
  return json ? JSON.parse(json) : new AppStateModel();
};