import React from 'react'
import { Link } from 'react-router-dom';
import lang from '../../services/lang';

export const AdminNav = () => {
  return (
    <div>
      <nav className="nav">
        <Link to="/admin/orders" className="nav-link">{lang.admin_nav_link_orders}</Link>
        <Link to="/admin/products" className="nav-link">{lang.admin_nav_link_products}</Link>
      </nav>
    </div>
  );
};
