import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom';
import { OrderViewer } from '../components/OrderViewer';
import ApiService from '../services/ApiService';
import { OrderModel, ProductModel } from '../models';
import lang from '../services/lang';

type TParams = { id: string };
type ProductMap = { [id: string]: ProductModel };

export const OrderDetailPage = (props: RouteComponentProps<TParams>) => {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(new OrderModel());
  const [productMap, setProductMap] = useState<ProductMap>({});

  useEffect(() => {
    setLoading(true);
    ApiService.getProducts(products => {
      const pm = {};
      products.forEach(p => pm[p.id] = p);
      setProductMap(pm);
      ApiService.getOrder(props.match.params.id, (order) => {
        setOrder(order);
        setLoading(false);
      });
    });
  }, [props.match.params.id]);

  if (loading) {
    return <h2>{lang.loading}</h2>
  }

  return (
    <div>
      <div className="my-3 alert alert-primary" role="alert">
        <strong>{lang.order_detail_info}</strong>
      </div>
      <OrderViewer order={order} productMap={productMap}/>
    </div>
  )
}
