import React, { useState } from 'react'
import { OrderItemModel, OrderModel, ProductModel } from '../models'
import ApiService from '../services/ApiService';
import lang, { getLang } from '../services/lang'

type ProductMap = { [id: string]: ProductModel };
type OrderViewerProps = {
  order: OrderModel,
  productMap: ProductMap
}

export const OrderViewer = (props: OrderViewerProps) => {
  const [order, setOrder] = useState({ ...props.order });

  let totalPrice = 0;
  for (let i = 0; i < order.items.length; ++i) {
    const item = order.items[i];
    totalPrice += item.unitPrice * item.quantity;
  }

  const cancelOrder = () => updateOrderStatus(1);
  const markAsDelivered = () => updateOrderStatus(2);

  const updateOrderStatus = (status: number) => {
    if (!confirm(lang.order_viewer_confirm_status_update)) {
      return;
    }
    const updatedOrder = { ...order, status };
    ApiService.updateOrder(updatedOrder, () => {
      setOrder(updatedOrder);
    });
  };

  const getProductName = (orderItem: OrderItemModel) => {
    const product = props.productMap[orderItem.productId];
    return product ? product.names[getLang()] : '?';
  };

  return (
    <div className="row order-box">
      <div className="col-12">
        <h4 className="clearfix">
          {order.customer.fullname}
          <small className="float-right mt-1">{formatDate(new Date(order.timestamp))}</small>
        </h4>
        <hr/>
      </div>
      <div className="col-6">
        <p>
          <strong>{lang.order_viewer_status_label}: </strong>
          {
            (order.status == 0 && <span className="badge badge-primary">{lang.order_status_active}</span>) ||
            (order.status == 1 && <span className="badge badge-danger">{lang.order_status_cancelled}</span>) ||
            (order.status == 2 && <span className="badge badge-success">{lang.order_status_delivered}</span>)
          }
        </p>
        <p><strong>{lang.order_viewer_phone_label}:</strong> {order.customer.phone}</p>
        <p><strong>{lang.order_viewer_address_label}:</strong> {order.customer.address}</p>
        <p><strong>{lang.order_viewer_email_label}:</strong> {order.customer.email}</p>
        <p><strong>{lang.order_viewer_notes_label}:</strong> {order.notes}</p>
      </div>
      <div className="col-6">
        <ul>
          {order.items.map(orderItem => <li>
            <p>{orderItem.quantity} x {getProductName(orderItem)} ({lang.currency_symbol}{orderItem.unitPrice})</p>
          </li>)}
        </ul>
      </div>
      <div className="col-12 col-sm-6">
        <h3 className="float-left">{lang.order_viewer_total_label}: {lang.currency_symbol}{totalPrice}</h3>
      </div>
      <div className="col-12 col-sm-6">
        {
          order.status == 0 && <>
            <span className="float-right">
              <button type="button" className="btn btn-outline-danger"
                      onClick={cancelOrder}>{lang.order_viewer_cancel_button}</button>
              {window.localStorage.getItem("authToken") &&
              <button type="button" className="btn btn-outline-success ml-3"
                      onClick={markAsDelivered}>{lang.order_viewer_delivered_button}</button>}
            </span>
          </>
        }
      </div>
    </div>
  )
}

function formatDate(date: Date) {
  const month = formatNumber(date.getMonth() + 1);
  const day = formatNumber(date.getDate());
  const hour = formatNumber(date.getHours());
  const minute = formatNumber(date.getMinutes());
  return `${date.getFullYear()}-${month}-${day} ${hour}:${minute}`;
}

function formatNumber(num) {
  if (num < 10)
    num = "0" + num;
  return num;
}