import React, { useEffect, useState } from 'react'
import { OrderViewer } from '../../../components/OrderViewer';
import { OrderModel, ProductModel } from '../../../models';
import ApiService from '../../../services/ApiService';
import lang from '../../../services/lang';

type ProductMap = { [id: string]: ProductModel };

export const OrderListPage = () => {
  const [orderStatus, setOrderStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<OrderModel[]>([]);
  const [productMap, setProductMap] = useState<ProductMap>({});

  useEffect(() => {
    setLoading(true);
    ApiService.getProducts(products => {
      var pm = {};
      products.forEach(p => pm[p.id] = p);
      setProductMap(pm);
      ApiService.getOrders(orderStatus, orders => {
        setOrders(orders);
        setLoading(false);
      });
    });
  }, [orderStatus]);

  const setOrderStatusFilter = e => {
    setOrderStatus(parseInt(e.target.value));
  }

  return (
    <div>
      <h2 className="clearfix">
        {lang.admin_order_list_title}
        <div className="btn-group float-right" role="group" aria-label="Basic example">
          <button type="button" className={`btn btn${orderStatus === 0 ? '' : '-outline'}-primary`}
                  onClick={setOrderStatusFilter} value={0}>{lang.order_status_active}</button>
          <button type="button" className={`btn btn${orderStatus === 1 ? '' : '-outline'}-danger`}
                  onClick={setOrderStatusFilter} value={1}>{lang.order_status_cancelled}</button>
          <button type="button" className={`btn btn${orderStatus === 2 ? '' : '-outline'}-success`}
                  onClick={setOrderStatusFilter} value={2}>{lang.order_status_delivered}</button>
          <button type="button" className={`btn btn${orderStatus === -1 ? '' : '-outline'}-secondary`}
                  onClick={setOrderStatusFilter} value={-1}>{lang.order_status_all}</button>
        </div>
      </h2>
      <hr/>
      {loading && <h2>{lang.loading}</h2>}
      {!loading && orders.length === 0 && <h2>{lang.admin_order_list_no_orders}</h2>}
      {!loading && orders.length > 0 && orders.map(order => <OrderViewer order={order} productMap={productMap}/>)}
    </div>
  )
}
