import React, { useContext } from 'react'
import { AppContext } from './App';
import { Link, useHistory } from 'react-router-dom';
import { LanguageSelector } from './LanguageSelector';
import lang from '../services/lang'

export const NavBar = () => {
  const history = useHistory();
  const { state } = useContext(AppContext);

  const count = state.cart.items.map(item => item.quantity).reduce((acc, curr) => acc + curr, 0);

  const isAdmin = window.localStorage.getItem("authToken");

  const signOut = () => {
    window.localStorage.removeItem("authToken");
    history.push("/");
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <Link to="/" className="navbar-brand mr-4">{lang.navbar_app_name}</Link>

      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <Link to="/about" className="nav-link">{lang.navbar_link_about}</Link>
        </li>
      </ul>

      <div className="text-right">
        <LanguageSelector/>
        {isAdmin
          ? <button type="button" className="btn btn-danger float-right"
                    onClick={signOut}>{lang.navbar_button_signout}</button>
          : <Link to="/cart" className="btn btn-outline-primary">
            <i className="fa fa-shopping-cart mr-2" aria-hidden="true"/>
            {lang.navbar_button_cart} ({count})
          </Link>
        }
      </div>
    </nav>
  );
};