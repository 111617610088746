import React from 'react'
import { useHistory } from 'react-router-dom';
import { ProductModel } from '../../../models'
import { ProductEditor } from '../../../components/ProductEditor'
import ApiService from '../../../services/ApiService';
import lang from '../../../services/lang';

export const CreateProductPage = () => {
  const history = useHistory();

  const createProduct = (product: ProductModel) => {
    ApiService.createProduct(product, () => history.push('/admin/products'));
  };

  return (
    <>
      <h2>{lang.admin_create_product_title}</h2>
      <hr/>
      <ProductEditor product={new ProductModel()} buttonText={lang.admin_create_product_title}
                     onSubmit={createProduct}/>
    </>
  )
}
