import React, { useContext, useState } from 'react'
import { CartItemModel } from '../models';
import { AppContext } from './App';
import { ACTION_UPDATE_QUANTITY_IN_CART } from '../reducer';
import lang, { getLang } from '../services/lang';

type CartItemProps = { cartItem: CartItemModel };

export const CartItem = ({ cartItem }: CartItemProps) => {
  const { quantity, product } = cartItem;
  const [newQuantity, setNewQuantity] = useState(quantity);
  const { dispatch } = useContext(AppContext);

  const updateQuantityInCart = () => {
    const quantity = newQuantity || 0;
    if (quantity === 0 && !confirm(lang.cart_remove_item_confirm)) {
      return;
    }
    dispatch({ type: ACTION_UPDATE_QUANTITY_IN_CART, payload: { cartItem, newQuantity: quantity } });
  };

  const onNewQuantityChanged = e => {
    const value = Math.max(0, Math.min(100, parseInt(e.target.value)));
    if (isNaN(value)) {
      setNewQuantity(null);
    } else {
      setNewQuantity(value);
    }
  };

  return (
    <div className="row cart-item">
      <div className="col-3 text-center">
        <img src={product.imageUrl} alt={product.names[getLang()]} className="img-thumbnail img-thumbnail-sm"/>
      </div>
      <div className="col-9">
        <div className="row">
          <div className="col-8">
            <h5>{quantity} x {product.names[getLang()]} ({lang.currency_symbol}{product.price})</h5>
          </div>
          <div className="col-4 text-right">
            <h3 className="mb-4">{lang.currency_symbol}{product.price * quantity}</h3>
          </div>
          <div className="col-12 text-right">
            <input type="number" max="100" min="0" className="new-quantity form-control mr-2"
                   value={newQuantity} onChange={onNewQuantityChanged}/>
            {
              newQuantity > 0
                ? <button type="button" className="btn btn-outline-primary btn-xs" onClick={updateQuantityInCart}>
                  {lang.cart_update_quantity}
                </button>
                : <button type="button" className="btn btn-outline-danger btn-xs" onClick={updateQuantityInCart}>
                  {lang.cart_remove_item}
                </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
