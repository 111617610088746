import React, { useContext, useState } from 'react'
import { ProductModel } from '../models';
import { AppContext } from './App';
import { ACTION_ADD_TO_CART } from '../reducer';
import lang, { getLang } from '../services/lang'

type ProductProps = { product: ProductModel }

export const ProductBox = ({ product }: ProductProps) => {
  const { dispatch } = useContext(AppContext);
  const [quantity, setQuantity] = useState(1);

  const addItemToCart = () => {
    dispatch({ type: ACTION_ADD_TO_CART, payload: { cartItem: { quantity, product } } });
  };

  return (
    <div className="product-box p-3 mb-3">
      <div className="row">
        <div className="col-12 col-md-3">
          <img src={product.imageUrl} className="img-thumbnail mb-3" alt={product.names[getLang()]}/>
        </div>
        <div className="col-12 col-md-9">
          <h2 className="clearfix">
            <span>{product.names[getLang()]}</span>
            <span className="float-right">{lang.currency_symbol}{product.price}</span>
          </h2>
          <p>{product.descriptions[getLang()]}</p>
        </div>
        <div className="text-right col-12">
          <span className="mr-3">{lang.product_quantity}</span>
          <select className="custom-select quantity-select mr-2" name="quantity" id="quantity"
                  defaultValue={quantity} onChange={e => setQuantity(parseInt(e.target.value))}>
            {Array.from(Array(10).keys()).map(i => <option key={i + 1} value={i + 1}>{i + 1}</option>)}
          </select>
          <button type="button" className="btn btn-outline-success" onClick={addItemToCart}>
            <i className="fa fa-shopping-cart mr-2" aria-hidden="true"/>
            {lang.product_add_to_cart}
          </button>
        </div>
      </div>
    </div>
  );
};