import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ProductModel } from '../../../models';
import ApiService from '../../../services/ApiService';
import lang from '../../../services/lang';
import { ProductEditor } from '../../../components/ProductEditor';

type TParams = { id: string };

export const UpdateProductPage = (props: RouteComponentProps<TParams>) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(new ProductModel());

  useEffect(() => {
    setLoading(true);
    ApiService.getProduct(props.match.params.id, (product) => {
      setProduct(product);
      setLoading(false);
    });
  }, [props.match.params.id]);

  const updateProduct = (product: ProductModel) => {
    ApiService.updateProduct(product, () => history.push('/admin/products'));
  };

  if (loading) {
    return <h3>{lang.loading}</h3>
  }

  return (
    <>
      <h2>{lang.admin_update_product_title}</h2>
      <hr/>
      <ProductEditor product={product} buttonText={lang.admin_update_product_title} onSubmit={updateProduct}/>
    </>
  )
}
