import React, { useRef, useState } from 'react'
import { CartModel, CustomerModel, OrderModel } from '../models';
import { Required } from './Required';
import ApiService from '../services/ApiService';
import lang from '../services/lang'

type CheckoutProps = {
  cart: CartModel
  onOrderCreated: (orderId: string) => void
};

export const Checkout = ({ cart, onOrderCreated }: CheckoutProps) => {
  const [customer, setCustomer] = useState(new CustomerModel());
  const [notes, setNotes] = useState('');
  const formRef = useRef(null);

  const validateForm = () => {
    const form = formRef.current;
    form.classList.add('was-validated');
    return form.checkValidity();
  };

  const placeOrder = e => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const order: OrderModel = {
      customer: customer,
      notes: notes,
      status: 0,
      items: cart.items.map(ci => {
        return {
          productId: ci.product.id,
          unitPrice: ci.product.price,
          quantity: ci.quantity
        };
      })
    };

    ApiService.createOrder(order, onOrderCreated);
  };

  const setCustomerField = e => {
    setCustomer({ ...customer, [e.target.name]: e.target.value });
  };

  let totalPrice = 0;
  for (let i = 0; i < cart.items.length; ++i) {
    const item = cart.items[i];
    totalPrice += item.product.price * item.quantity;
  }

  return (
    <form className="needs-validation" noValidate={true} ref={formRef} onSubmit={placeOrder}>
      <div className="form-group">
        <label htmlFor="fullname">{lang.checkout_fullname_label} <Required/></label>
        <input type="text" value={customer.fullname} onChange={setCustomerField} className="form-control"
               name="fullname" id="fullname" maxLength={100} placeholder={lang.checkout_fullname_placeholder}
               required={true}/>
        <div className="invalid-feedback">{lang.checkout_fullname_validation_error}</div>
      </div>
      <div className="form-group">
        <label htmlFor="phone">{lang.checkout_phone_label} <Required/></label>
        <input type="text" value={customer.phone} onChange={setCustomerField} className="form-control" name="phone"
               id="phone" maxLength={25} placeholder={lang.checkout_phone_placeholder} required={true}/>
        <div className="invalid-feedback">{lang.checkout_phone_validation_error}</div>
      </div>
      <div className="form-group">
        <label htmlFor="email">{lang.checkout_email_label} <Required/></label>
        <input type="text" value={customer.email} onChange={setCustomerField} className="form-control" name="email"
               id="email" maxLength={100} placeholder={lang.checkout_email_placeholder} required={true}/>
        <div className="invalid-feedback">{lang.checkout_email_validation_error}</div>
      </div>
      <div className="form-group">
        <label htmlFor="address">{lang.checkout_address_label} <Required/></label>
        <textarea value={customer.address} onChange={setCustomerField} className="form-control" name="address"
                  id="address" rows={3} maxLength={150} placeholder={lang.checkout_address_placeholder}
                  required={true}/>
        <div className="invalid-feedback">{lang.checkout_address_validation_error}</div>
      </div>
      <div className="form-group">
        <label htmlFor="notes">{lang.checkout_notes_label}</label>
        <textarea value={notes} onChange={e => setNotes(e.target.value)} className="form-control" name="notes"
                  id="notes" rows={3} maxLength={500} placeholder={lang.checkout_notes_placeholder}/>
      </div>
      <div className="row">
        <div className="col-12">
          <hr className="mb-4"/>
        </div>
        <div className="col-6"><h4>{lang.checkout_total_price_label}</h4></div>
        <div className="col-6 text-right"><h4>{lang.currency_symbol}{totalPrice}</h4></div>
        <div className="col-12 text-right mt-3">
          <button type="submit" className="btn btn-primary">{lang.checkout_place_order_button}</button>
        </div>
      </div>
    </form>
  );
};
