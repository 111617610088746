export class ProductModel {
  id: string = '';
  names?: { [lang: string]: string; } = {};
  descriptions?: { [lang: string]: string; } = {};
  price: number = 0;
  imageUrl: string = '';
}

export class CartModel {
  items: CartItemModel[] = [];
}

export class CartItemModel {
  product: ProductModel;
  quantity: number;
}

export class OrderModel {
  id?: string = '';
  notes: string = '';
  customer: CustomerModel;
  status: number = 0;
  timestamp?: number = 0;
  items: OrderItemModel[] = [];
}

export class CustomerModel {
  fullname: string = '';
  phone: string = '';
  email: string = '';
  address: string = '';
}

export class OrderItemModel {
  quantity: number = 0;
  productId: string = '';
  unitPrice: number = 0;
}

export class AppStateModel {
  cart: CartModel = new CartModel();
}
